.modal-bg {
  background-color: rgba(0, 0, 0, 0.090);
  height: 100vh;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
  
.modal-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; 
  background-color: white;
  padding: 20px;
  width: 80%;
  height: auto;
  margin: 0 auto;
  box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
}

.modal-container button {
  display: flex;
  background: #CABA9E;
  padding: 10px;
  margin-top: 10px;
  text-align: center;
  border-style: none;
  color: white;
  font-weight: bold;
  
}

.modal-container button:hover {
  background: beige;
  color : black;
}
