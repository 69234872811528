.SignupForm {
    position: absolute;
    margin-top: 70px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.Header-home {
    width: 100%;
    height: 90px;
    background-color: rgb(43, 43, 43);
    position: sticky;
    top: 0;
    margin: 0;
    padding: 0;
    font-family: "Josefin Sans", sans-serif;
}  
  