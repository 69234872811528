.Loading {
    text-align: center;
  }
  
  .Loading-loadingIcon {
    height: 10%;
    width: 10%;
    pointer-events: none;
  }
  
  @media (prefers-reduced-motion: no-preference) {
    .Loading-loadingIcon {
      animation: Loading-loadingIcon-spin infinite 2s linear;
    }
  }
  
  .Loading-header {
    background-color: #ffffff;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: rgb(0, 0, 0);
  }
  
 
  
  @keyframes Loading-loadingIcon-spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }