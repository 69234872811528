.Header {
  width: 100%;
  height: 90px;
  background-color: rgb(43, 43, 43);
  position: sticky;
  top: 0;
  margin: 0;
  padding: 0;
  font-family: "Josefin Sans", sans-serif;
}

.Header-wrapper {
  height: 90px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.Header-logo {
  margin-left: 5px;
  margin-right: 5px;
  max-height: 70px;
  max-width: 97%;
}

.Header-center {
  margin-left: auto;
  margin-right: auto;
}

.Header-right {
  margin-left: auto;
}

.Header-right-logout button {
  height: 90px;
  width: 100%;
  font-weight: bold;
  border: none;
  color: white;
  background-color: #CABA9E;
}

.Header-right-logout button:hover {
  background-color: beige;
  color: black;
}

a {
  height: 90px;
  color: #CABA9E;
}

a:hover {
  color: white;
}

.Header-list {
  list-style: none;
  /*margin-right: 20px;*/
  font-size: 18px;
  font-weight: 300;
  color: #CABA9E;
  margin-block-start: 0px;
  margin-block-end: 0px;
  padding-inline-start: 5px;
  padding-inline-end: 5px;
}