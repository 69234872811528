.Error {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    justify-content: center;
}

.Error a {
    color: black;
    
}

.Header-error {
    width: 100%;
    background-color: rgb(43, 43, 43);
    position: sticky;
    top: 0;
    margin: 0;
    padding: 0;
    font-family: "Josefin Sans", sans-serif;
}  