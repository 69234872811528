.App {
  width: 100%;
  background-color: rgb(43, 43, 43);
  text-align: center;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.App-header {
  min-height: 25vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

.App-header p {
  color: white;
}

.App-logo {
  margin-left: 5px;
  margin-right: 5px;
  max-height: 70px;
  max-width: 97%;
}

.App-wrapper {
  flex: 1;
}
.App-links {
  margin-top: 70px;
  display: flex;
  align-items: center;
  flex-direction: column;
  
}

.App-dashboard-link {
  display: block;
  width: 115px;
  height: 25px;
  background: #CABA9E;
  padding: 10px;
  text-align: center;
  border-radius: 5px;
  color: white;
  font-weight: bold;
  line-height: 25px;
}

.App-dashboard-link:hover {
  background-color: rgb(255, 255, 255);
  color : black;  
}


/*.App-footer{
  
}*/

.buttonLogout {
  height: 25px;
  width: 115px;
  border : none;
  color :black;
  background-color: rgb(255, 255, 0);
}

.buttonLogout:hover {
  background-color: white;
  color : black;
}

.App-footer p {
  margin : 0;
  background-color: rgb(43, 43, 43);
  color: white;
}

