.searchInputs {
    margin-top: 70px;
    display: flex;
    justify-content: center;
    
    
}

.search input {
    background-color: beige;
    border: 0;
    border-radius: 2px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    font-size: 18px;
    padding: 15px;
    height: 30px;
    width: 300px;
    -webkit-box-shadow:inset 0px 0px 0px 5px #CABA9E;
    -moz-box-shadow:inset 0px 0px 0px 5px #CABA9E;
    box-shadow:inset 0px 0px 0px 5px #CABA9E;
}

.searchIcon {
    height: 60px;
    width: 50px;
    color: white;
    background-color: #CABA9E;
    border-top-right-radius: 2px;
    border-bottom-right-radius: 2px;
    display: grid;
    place-items: center;
}

input:focus {
    outline: none;
}

.searchIcon svg {
    font-size: 35px;
}

.dataResult {
    margin-top: 0px;
    width: auto;
    max-height: 60vh;
    box-sizing: border-box;
    border: none;
    background-color: beige;
    box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px;
    overflow: hidden;
    overflow-y: auto;
}

.Infos {
    margin-top: 5px;
    width: auto;
    height: 400px;
    background-color: beige;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
    overflow: hidden;
    overflow-y: auto;
}

.Infos h2 {
    margin-left: 10px;
    font-size: 0.8em;
    margin-block-start: 0.5em;
    margin-block-end: 0.5em;
}

span {
    font-weight: normal;

}

.Description{
    margin: 10px;
    padding: 10px;
    border: 1px solid #CABA9E;
}

/*
.dataResult::-webkit-scrollbar {
    display: none;
}
*/

.Article {
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
}

.buttonArticle{
    height: 100%;
    width: 100%;
    display: block;
    border : none;
    color :black;
    background-color: beige;
}

.buttonArticle:hover{
    background-color: #CABA9E;
}


a {
    text-decoration: none;
}

#clearBtn {
    cursor: pointer;
}


.Infos a {
    color : black;
}

.Infos a:hover {
    color : #CABA9E;
}

.Infos img {
    max-height: 200px;
}

.Modal img {
    max-height: 40px;
}